import {Box, Container, Typography} from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import {StackedBarChart} from "@mui/icons-material";
import CountUp from "react-countup";

const Stats = () => {
    return (
        <Container>
            <Box mt={2}>
                <Box mb={2} sx={{ marginTop: '20px', display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <StackedBarChart sx={{ width: { xs: '30px', sm: '50px' }, height: { xs: '30px', sm: '50px' } }}/>
                    <Typography sx={{ marginLeft: '10px', fontSize: { xs: '24px' } }} variant={'h4'}>Компания в цифрах</Typography>
                </Box>
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} flexWrap={'wrap'} width={'100%'} justifyContent={{ xs: 'space-between', sm: 'space-between', md: 'space-between' }}>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { sm: '200px', xs: '160px' }, padding: '8px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '24px' }}><strong><CountUp duration={2} end={5}/></strong></Typography>
                    <Typography sx={{ height: '40px', textAlign: 'center', marginTop: '20px', fontSize: '14px' }}>городов</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { sm: '200px', xs: '160px' }, padding: '8px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '24px' }}><strong><CountUp duration={2} end={3}/></strong></Typography>
                    <Typography sx={{ height: '40px', textAlign: 'center', marginTop: '20px', fontSize: '14px' }}>области</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { sm: '200px', xs: '160px' }, padding: '8px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '24px' }}><strong><CountUp duration={2} end={40}/>+</strong></Typography>
                    <Typography sx={{ height: '40px', textAlign: 'center', marginTop: '20px', fontSize: '14px' }}>станций</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',width: { sm: '200px', xs: '160px' }, padding: '8px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '24px' }}><strong><CountUp duration={2} end={15}/>+</strong></Typography>
                    <Typography sx={{ height: '40px', textAlign: 'center', marginTop: '20px', fontSize: '14px' }}>партнеров</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',width: { sm: '200px', xs: '160px' }, padding: '8px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '24px' }}><strong><CountUp duration={2} end={4500}/></strong></Typography>
                    <Typography sx={{ height: '40px', textAlign: 'center', marginTop: '20px', fontSize: '14px' }}>пользователей</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',width: { sm: '200px', xs: '160px' }, padding: '8px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '24px' }}><strong><CountUp duration={2} end={4300}/></strong></Typography>
                    <Typography sx={{ height: '40px', textAlign: 'center', marginTop: '20px', fontSize: '14px' }}>постоянных пользователей</Typography>
                </Box>
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} flexWrap={'wrap'} width={'100%'} justifyContent={{ xs: 'center', md: 'space-between' }}>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { lg: '300px', md: '260px' }, padding: '20px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '36px' }}><strong><CountUp duration={2} end={5}/></strong></Typography>
                    <Typography sx={{ textAlign: 'center', marginTop: '20px', fontSize: '22px' }}>городов</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { lg: '300px', md: '260px' }, padding: '20px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '36px' }}><strong><CountUp duration={2} end={3}/></strong></Typography>
                    <Typography sx={{ textAlign: 'center', marginTop: '20px', fontSize: '22px' }}>области</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)', width: { lg: '300px', md: '260px' }, padding: '20px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '36px' }}><strong><CountUp duration={2} end={40}/>+</strong></Typography>
                    <Typography sx={{ textAlign: 'center', marginTop: '20px', fontSize: '22px' }}>станций</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',width: { lg: '300px', md: '260px' }, padding: '20px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '36px' }}><strong><CountUp duration={2} end={15}/>+</strong></Typography>
                    <Typography sx={{ textAlign: 'center', marginTop: '20px', fontSize: '22px' }}>партнеров</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',width: { lg: '300px', md: '260px' }, padding: '20px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '36px' }}><strong><CountUp duration={2} end={4500}/></strong></Typography>
                    <Typography sx={{ textAlign: 'center', marginTop: '20px', fontSize: '22px' }}>пользователей</Typography>
                </Box>
                <Box sx={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',width: { lg: '300px', md: '260px' }, padding: '20px', borderRadius: '15px' }} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography sx={{ fontSize: '36px' }}><strong><CountUp duration={2} end={4300}/></strong></Typography>
                    <Typography sx={{ textAlign: 'center', marginTop: '20px', fontSize: '22px' }}>постоянных пользователей</Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default Stats;